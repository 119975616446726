// src/api.js
import { availableBookingTime, BookingSettings, IBooking } from '@/@type/booking';
import { Merchant } from '@/__generated__/types';
import { store } from '@/redux/store';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { bookingAPIPath } from './consts';
import { IBookingApiResponse, IBookingReqBody, IMerchantAreas, IRequestOptions } from './type';

const clientID = import.meta.env.VITE_BOOKING_CLIENT_ID || 'e818e73b-8f06-4d94-acdc-d7f7f2330ef7';

const axiosClient = axios.create({
    baseURL: import.meta.env.VITE_BOOKING_HOST,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const setAccessToken = (token: string): void => {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

axiosClient.interceptors.request.use(
    async (config) => {
        config.headers.clientId = clientID;
        const state = store.getState();
        const token = state.auth.user?.loginToken;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

async function request<TResponse, TBody = unknown>(options: IRequestOptions<TBody>): Promise<IBookingApiResponse<TResponse>> {
    const { path, method, params, body } = options;
    const fullPath = `${axiosClient.defaults.baseURL}${path.startsWith('/') ? path : `/${path}`}`;
    if (body && typeof body === 'object' && 'updatedAt' in body) {
        delete body.updatedAt;
    }
    const config: AxiosRequestConfig = {
        url: fullPath,
        method,
        params,
        data: body
    };
    try {
        const response = await axiosClient.request<IBookingApiResponse<TResponse>>(config);
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError;
        const response = axiosError.response as AxiosResponse;
        console.error('request error', response);
        return response.data;
    }
}

export async function bookingMerchantQuery(merchantId: string): Promise<Merchant | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.merchant.query(merchantId);
    const options: IRequestOptions = {
        path: endPoint.url,
        method: endPoint.method,
        params: {}
    };
    // call the request function
    const resp = await request<Merchant>(options);
    return resp.data;
}

export async function bookingCreate(payload: IBookingReqBody): Promise<IBookingApiResponse<IBooking> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.public.createBookingByCustomer(payload.merchantId);
    const options: IRequestOptions<IBookingReqBody> = {
        path: endPoint.url,
        method: endPoint.method,
        body: payload
    };
    // call the request function
    const resp = await request<IBooking, IBookingReqBody>(options);
    return resp;
}

export async function bookingQuery(
    merchantId: string,
    yearStr: string,
    bookingNumber: string
): Promise<IBookingApiResponse<IBooking> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.booking.query(merchantId, yearStr, bookingNumber);
    const options: IRequestOptions = {
        path: endPoint.url,
        method: endPoint.method
    };
    // call the request function
    const resp = await request<IBooking>(options);
    return resp;
}

export async function bookingUpdateByStaff(payload: Partial<IBooking>): Promise<IBookingApiResponse<boolean> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.booking.updateByStaff(payload?.merchantId || '');
    const options: IRequestOptions<Partial<IBooking>> = {
        path: endPoint.url,
        method: endPoint.method,
        body: payload
    };
    // call the request function
    const resp = await request<boolean>(options);
    return resp;
}

export async function bookingUpdateByCustomer(payload: Partial<IBooking>): Promise<IBookingApiResponse<boolean> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.public.updateBookingByCustomer(payload?.merchantId || '');
    const options: IRequestOptions<Partial<IBooking>> = {
        path: endPoint.url,
        method: endPoint.method,
        body: payload
    };
    // call the request function
    const resp = await request<boolean>(options);
    return resp;
}

export async function bookingAvailableTimesQuery(
    merchantId: string,
    timestamp: number,
    partySize?: number,
    excludeBookingNumber?: string
): Promise<IBookingApiResponse<availableBookingTime[]> | undefined> {
    const params: Record<string, string | number> = { timestamp };

    if (partySize !== undefined) {
        params.partySize = partySize;
    }
    if (excludeBookingNumber !== undefined) {
        params.excludeBookingNumber = excludeBookingNumber;
    }
    // build the request options
    const endPoint = bookingAPIPath.booking.queryBookingAvailableTime(merchantId);
    const options: IRequestOptions = {
        path: endPoint.url,
        method: endPoint.method,
        params
    };
    // call the request function
    const resp = await request<availableBookingTime[]>(options);
    return resp;
}

export async function bookingMerchantBookingSettingsUpdate(
    merchantId: string,
    bookingSettings: BookingSettings
): Promise<BookingSettings | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.booking.updateBookingSettings(merchantId);
    const options: IRequestOptions<BookingSettings> = {
        path: endPoint.url,
        method: endPoint.method,
        body: bookingSettings
    };
    // call the request function
    const resp = await request<BookingSettings>(options);
    return resp.data;
}
/**
 * Fetches the list of bookings for a given merchant and query parameters.
 *
 * @param {string} merchantId - The ID of the merchant.
 * @param {Object} queryParams - The query parameters.
 * @returns {Promise<IBooking[] | undefined>} - A promise that resolves to an array of bookings or undefined.
 */
async function fetchBookingList(merchantId: string, queryParams: { [key: string]: string }): Promise<IBooking[] | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.booking.queryBookingList(merchantId);
    const options: IRequestOptions<IBooking[]> = {
        path: endPoint.url,
        method: endPoint.method,
        params: queryParams
    };
    // call the request function
    const resp = await request<IBooking[]>(options);
    return resp.data;
}

/**
 * Fetches the list of bookings for a given merchant and date.
 *
 * @param {string} merchantId - The ID of the merchant.
 * @param {string} dateString - The date string in ISO 8601 format.
 * @returns {Promise<IBooking[] | undefined>} - A promise that resolves to an array of bookings or undefined.
 */
export async function bookingListQuery(merchantId: string, dateString: string): Promise<IBooking[] | undefined> {
    return fetchBookingList(merchantId, { dateString });
}

/**
 * Fetches the list of bookings for a given merchant and month.
 *
 * @param {string} merchantId - The ID of the merchant.
 * @param {string} monthString - The month string in ISO 8601 format.
 * @returns {Promise<IBooking[] | undefined>} - A promise that resolves to an array of bookings or undefined.
 */
export async function bookingListByMonthQuery(merchantId: string, monthString: string): Promise<IBooking[] | undefined> {
    return fetchBookingList(merchantId, { monthString });
}

export async function bookingMerchantBookingSettingsQuery(merchantId: string): Promise<BookingSettings | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.booking.queryBookingSettings(merchantId);
    const options: IRequestOptions<BookingSettings> = {
        path: endPoint.url,
        method: endPoint.method
    };
    // call the request function
    const resp = await request<BookingSettings>(options);
    return resp.data;
}

export async function merchantAreasQuery(merchantId: string): Promise<IBookingApiResponse<IMerchantAreas> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.merchant.areas.query(merchantId);
    const options: IRequestOptions = {
        path: endPoint.url,
        method: endPoint.method
    };
    // call the request function
    const resp = await request<IMerchantAreas>(options);
    return resp;
}

export async function merchantAreasInit(merchantId: string): Promise<IBookingApiResponse<IMerchantAreas> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.merchant.areas.init(merchantId);
    const options: IRequestOptions = {
        path: endPoint.url,
        method: endPoint.method
    };
    // call the request function
    const resp = await request<IMerchantAreas>(options);
    return resp;
}

export async function merchantAreasUpdate(
    merchantId: string,
    payload: IMerchantAreas
): Promise<IBookingApiResponse<IMerchantAreas> | undefined> {
    // build the request options
    const endPoint = bookingAPIPath.merchant.areas.update(merchantId);
    const options: IRequestOptions<IMerchantAreas> = {
        path: endPoint.url,
        method: endPoint.method,
        body: payload
    };
    // call the request function
    const resp = await request<IMerchantAreas>(options);
    return resp;
}

export default request;
